<!--
 * @Author: faf
 * @desc: 车场收入明细
 * @Date: 2022-07-08 17:20:49
 * @LastEditors: faf
 * @ LastEditTime:
-->
<template>
  <div>
    <div class="searchWrapper" @keydown.enter.prevent="">
      <el-form
        :inline="true"
        status-icon
        label-position="left"
        :model="formInline"
        ref="formInline"
        class="demo-form-inline"
      >
        <el-form-item :label="$t('searchModule.region')" prop="areaId">
          <a-cascader ref="cascader"></a-cascader>
        </el-form-item>
        <el-form-item :label="$t('searchModule.Belonging_operator')">
          <a-operation-select ref="operationSelect"></a-operation-select>
        </el-form-item>
        <el-form-item :label="$t('searchModule.Type_of_parking_lot')">
          <a-park-type-select ref="parkTypeSelect"></a-park-type-select>
        </el-form-item>
        <el-form-item :label="$t('searchModule.Road_or_Parking_Lot')">
          <!-- <el-input v-model="formInline.parkName" placeholder="请输入内容"></el-input> -->
          <a-park-select
            ref="parkSelect"
            :instance="this"
            parkTypeRefName="parkTypeSelect"
            operationRefName="operationSelect"
            areaRefName="cascader"
          ></a-park-select>
        </el-form-item>
        <!-- <el-form-item :label="$t('searchModule.charge_type')">
          <el-select v-model.trim="formInline.scopeId" filterable size="15">
            <el-option label="全部" value=""></el-option>
            <el-option label="一类区" value="1"></el-option>
            <el-option label="二类区" value="2"></el-option>
            <el-option label="三类区" value="3"></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="时间">
          <a-date-picker
            ref="datePicker"
            :selectkeys="selectkeys"
            :YearShowHidden="selectkeys[3]"
            :isPreviousday="isPreviousday"
          ></a-date-picker>
        </el-form-item>
        <el-button
          type="primary"
          icon="el-icon-search"
          @click="
            pageNum = 1;
            searchData();
          "
          :loading="loading"
          style="margin: 0 0 12px 12px"
          >查询
        </el-button>
        <el-button type="primary" icon="el-icon-refresh" @click="resetForm()" :loading="loading"
          >{{ $t('button.reset') }}</el-button
        >
        <!-- v-if="$route.meta.authority.button.export" -->
        <el-button :loading="loading" type="primary" icon="el-icon-upload2" @click="exportHandler"
          >{{ $t('button.export') }}</el-button
        >
      </el-form>
    </div>
    <div class="tableWrapper">
      <el-table
        v-loading="loading"
        :data="tableData"
        class="table-content"
        @sort-change="arraySpanMethod"
      >
        <el-table-column
          align="center"
          :prop="item.prop"
          :label="item.label"
          :width="item.width"
          v-for="item in tableCols"
          :key="item.prop"
          :formatter="item.formatter"
        ></el-table-column>
        <el-table-column align="center" prop="actualPay" label="收入（元）" sortable="true">
          <template slot-scope="scope">
            {{ scope.row.actualPay ? (scope.row.actualPay / 100).toFixed(2) : "0.00" }}
          </template>
        </el-table-column>
      </el-table>
      <!--分页器-->
      <div class="pagerWrapper">
        <div class="block">
          <el-pagination
            v-if="total != 0"
            @current-change="handleCurrentChange"
            :current-page="pageNum"
            :page-size="pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total"
          ></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { exportExcelNew, formatWeekDate } from "@/common/js/public.js";
import graphTitle from "@/components/graphTitle";
/* eslint-disable */
export default {
  name: "incomeAnalysis",
  data() {
    const startTime = new Date();
    startTime.setTime(startTime.getTime() - 31 * 24 * 60 * 60 * 1000);
    const endTime = new Date();
    endTime.setTime(endTime.getTime() - 3600 * 1000 * 24);
    return {
      selectkeys: ["date", "month", "week", "year"],
      isPreviousday: true,
      defaultTime: [startTime, endTime],
      // 区域列表
      areaList: [],
      // 区域街道
      areaList2: [],
      // 计费类型
      billingTypes: [],
      loading: false,
      orderType: 2,
      formInline: {
        // 区域
        areaId: "",
        // 区域
        streetId: "",
        // 车场ID
        parkId: "",
        // 车场名称
        parkName: "",
        // 计费类型
        scopeId: "",
        startTime: "",
        endTime: "",
        dateType: "day",
      },
      tableData: [],
      tableCols: [
        {
          prop: "parkName",
          label: "道路/车场",
          width: "auto",
        },
        {
          prop: "areaName",
          label: this.$t("list.region"),
          width: "auto",
        },
        {
          prop: "operationName",
          label: this.$t("list.Belonging_operator"),
          width: "auto",
        },
        {
          prop: "dataSource",
          label: this.$t("list.Type_of_parking_lot"),
          width: "auto",
          formatter: (row) => {
            return row.dataSource == 1 ? "路内车场" : "路外车场";
          },
        },
      ],
      total: 0,
      pageNum: 1,
      pageSize: 15,
    };
  },
  components: {
    graphTitle,
  },
  watch: {},
  methods: {
    parkClear() {
      this.$refs.parkSelect.clear();
    },
    // 清空车场组件信息
    resetCarPark() {
      this.formInline.parkId = "";
      this.formInline.parkName = "";
      // this.$refs.parkInput.setShowVal("");
    },

    arraySpanMethod(val) {
      if (val.order == "ascending") {
        this.orderType = 1;
      } else {
        this.orderType = 2;
      }
      this.searchData();
    },

    handleCurrentChange(val) {
      this.pageNum = val;
      this.searchData();
    },

    // 清空
    resetForm() {
      this.formInline.areaId = "";
      this.formInline.streetId = "";
      this.formInline.scopeId = "";
      this.formInline.dateType = "day";
      this.resetCarPark();
      // const startTime = new Date();
      // startTime.setTime(startTime.getTime() - 31 * 24 * 60 * 60 * 1000);
      // const endTime = new Date();
      // endTime.setTime(endTime.getTime() - 3600 * 1000 * 24);
      // this.startTime = startTime
      // this.endTime = endTime
      this.$refs.cascader.clear();
      this.$refs.operationSelect.clear();
      this.$refs.datePicker.clear();
      this.$refs.parkTypeSelect.clear();
      this.parkClear();
      this.searchData();
    },
    // 初始化用户区域下拉列表（区域和街道）
    getArea() {
      this.$axios.get("/acb/2.0/systems/loginUser/initAreaList").then((res) => {
        if (res.value.depth == 3) {
          this.areaList = res.value.areaList;
          this.formInline.areaId = "";
        } else if (res.value.depth == 4) {
          this.areaList2 = res.value.areaList;
          this.formInline.areaId = "";
        }
      });
    },
    getChildrenArea(item) {
      this.$axios
        .get("/acb/2.0/systems/loginUser/getChildrenArea", {
          data: {
            areaId: item,
          },
        })
        .then((res) => {
          this.areaList2 = res.value;
          this.formInline.streetId = "";
        });
    },
    // 计费类型
    billingFn() {
      this.$axios.get("/acb/2.0/systems/loginUser/initScopeList").then((res) => {
        this.billingTypes = res.value.scopeList;
      });
    },

    // 查询夜间停车列表
    searchData() {
      // /parkIncomeAnalysis/parkPayList
      this.loading = true;
      this.$axios
        .get("/acb/2.0/aceParkAnalysis/income/detailList", {
          data: {
            pageNum: this.pageNum,
            pageSize: this.pageSize,
            operationId: this.$refs.operationSelect
              ? this.$refs.operationSelect.getOperationId()
              : "",
            parkType: this.$refs.parkTypeSelect ? this.$refs.parkTypeSelect.getParkType() : "",
            dateType: this.$refs.datePicker ? this.$refs.datePicker.getDateType() : "",
            startTime: this.$refs.datePicker ? this.$refs.datePicker.getStartDate() : "",
            endTime: this.$refs.datePicker ? this.$refs.datePicker.getEndDate() : "",
            streetId: this.$refs.cascader ? this.$refs.cascader.getStreetId() : "",
            areaId: this.$refs.cascader ? this.$refs.cascader.getAreaId() : "",
            parkId: this.$refs.parkSelect ? this.$refs.parkSelect.getParkId() : "",
            orderType: this.orderType,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.tableData = res.value.list || [];
            this.total = res.value.total * 1 || 0;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    getDateType() {
      let dateType = this.$refs.datePicker ? this.$refs.datePicker.getDateType() : "";
      let val = "";
      switch (dateType) {
        case 1:
          val = "day";
          break;
        case 5:
          val = "week";
          break;
        case 2:
          val = "month";
          break;
        case 3:
          val = "year";
          break;
        default:
          break;
      }
      return val;
    },
    // 导出
    exportHandler() {
      const tempData = {
        operationId: this.$refs.operationSelect ? this.$refs.operationSelect.getOperationId() : "",
        dateType: this.$refs.datePicker ? this.$refs.datePicker.getDateType() : "",
        startTime: this.$refs.datePicker ? this.$refs.datePicker.getStartDate() : "",
        endTime: this.$refs.datePicker ? this.$refs.datePicker.getEndDate() : "",
        streetId: this.$refs.cascader ? this.$refs.cascader.getStreetId() : "",
        areaId: this.$refs.cascader ? this.$refs.cascader.getAreaId() : "",
        parkId: this.$refs.parkSelect ? this.$refs.parkSelect.getParkId() : "",
        scopeId: this.formInline.scopeId,
        parkType: this.$refs.parkTypeSelect ? this.$refs.parkTypeSelect.getParkType() : "",
        orderType: this.orderType,
      };
      const url = "/acb/2.0/aceParkAnalysis/income/detailList/export";
      // const url = "/acb/2.0/parkIncomeAnalysis/parkPayList/export";
      exportExcelNew(url, tempData, "post");
    },
  },
  created() {
    this.getArea();
  },
  mounted() {
    this.searchData();
  },
  computed: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb {
  height: 35px;
}



.tableWrapper {
  .table-content {
    /deep/ thead {
      width: 100%;
      height: 54px;
      box-shadow: inset 0px -1px 0px 0px rgba(0, 0, 0, 0.1);
      border-radius: 4px 4px 0px 0px;

      th {
        background-color: #F3F7FF;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.85);
        line-height: 22px;
        font-size: 14px;
      }
    }
  }
}

.pagerWrapper {
  background: #fff;
  padding: 20px 10px 20px 0;
  margin-top: 0;
}
</style>
